import Footer from "../components/Footer";
import Header from "../components/Header";

import {
  FiPhone,
  FiMail,
  FiMapPin,
  FiX,
  FaWhatsapp,
} from "../assets/icons/vander";
import { Link } from "react-router-dom";

import travel from "../assets/images/travel-train-station.svg";
import { useTranslation } from "react-i18next";

interface ContactUsProps {}

const ContactUs = ({}: ContactUsProps) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Header />
      <main
        className={`${
          i18n.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        {/* map section ************/}
        <section className=" relative ">
          <div className="grid grid-cols-1">
            <div className="w-full leading-[0] border-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.6406137231065!2d31.237249999999996!3d30.04716670000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzQ5LjgiTiAzMcKwMTQnMTQuMSJF!5e0!3m2!1sen!2seg!4v1723114058488!5m2!1sen!2seg"
                style={{ border: "0" }}
                title="travosy"
                className="w-full h-[500px]"
              ></iframe>
            </div>
          </div>
        </section>

        {/* the contact section  ******/}
        <section className="relative lg:py-24 py-16">
          {/* upper section contact form  ******/}
          <div className="px-[20px] lg:px-[140px]">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
              <div className="lg:col-span-7 md:col-span-6">
                <img
                  src={travel}
                  className="w-full max-w-[500px] mx-auto"
                  alt=""
                />
              </div>

              <div className="lg:col-span-5 md:col-span-6 font-[600]">
                <div>
                  <p className="mt-6">
                    {t("You can contact with us via whats app")}
                  </p>

                  <div className="grid grid-cols-1">
                    {/* <div className="my-3">
                        <label className="form-label">
                          Write your email{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <FiMail className="size-4 absolute top-3 start-4"></FiMail>
                          <input
                            type="email"
                            className="ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200 outline-none"
                            placeholder="Email"
                            name="email"
                            // required=""
                          />
                        </div>
                      </div> */}

                    <Link
                      to="https://wa.me/+201008505071"
                      className="mt-[10px] py-2 px-5  font-semibold tracking-wide align-middle duration-500 text-base text-center bg-second_color text-white rounded-md flex items-center justify-center gap-2"
                    >
                      <FaWhatsapp className="text-[20px]"></FaWhatsapp> Whats
                      app
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* lower section contact info  *******/}
          <div className="px-[20px] lg:px-[140px] lg:mt-24 mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
              <div className="text-center px-6">
                <div className="relative text-transparent">
                  <div className="size-20 bg-second_color/5 text-second_color rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm ">
                    <FiPhone></FiPhone>
                  </div>
                </div>

                <div className="content mt-7">
                  <h5 className="h5 text-lg font-semibold">{t("Phone")}</h5>
                  <p className="text-slate-400 mt-3">
                    {t("You can contact with us with the phone number below")}
                  </p>

                  <div className="mt-5">
                    <Link
                      to="https://wa.me/+201008505071"
                      className="text-second_color font-medium"
                    >
                      +201008505071
                    </Link>
                  </div>
                </div>
              </div>

              <div className="text-center px-6">
                <div className="relative text-transparent">
                  <div className="size-20 bg-second_color/5 text-second_color rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm ">
                    <FiMail></FiMail>
                  </div>
                </div>

                <div className="content mt-7">
                  <h5 className="h5 text-lg font-semibold">{t("Email")}</h5>
                  <p className="text-slate-400 mt-3">
                    {t("You can contact with us with the email below")}
                  </p>

                  <div className="mt-5">
                    <Link
                      to="mailto:info@onepanorama.com"
                      className="text-second_color font-medium"
                    >
                      info@onepanorama.com
                    </Link>
                  </div>
                </div>
              </div>

              <div className="text-center px-6">
                <div className="relative text-transparent">
                  <div className="size-20 bg-second_color/5 text-second_color rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm ">
                    <FiMapPin></FiMapPin>
                  </div>
                </div>

                <div className="content mt-7">
                  <h5 className="h5 text-lg font-semibold">{t("Location")}</h5>
                  <p className="text-slate-400 mt-3">
                    13 Kasr ElNil, St. Downtown. Cairo
                  </p>

                  <div className="mt-5">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/30%C2%B002'49.8%22N+31%C2%B014'14.1%22E/@30.0471441,31.2372741,16.54z/data=!4m4!3m3!8m2!3d30.0471667!4d31.23725?hl=en&entry=ttu"
                      // onClick={() => setModal(!modal)}
                      className="video-play-icon read-more lightbox text-second_color font-medium"
                    >
                      {t("View on Google map")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default ContactUs;
