import i18next from "i18next";
import DayTours from "components/DayTours";
import Footer from "components/Footer";
import Header from "components/Header";
import Hero from "components/Hero";

interface DayToursPageProps {}

const DayToursPage = ({}: DayToursPageProps) => {
  return (
    <>
      <Header />
      <main
        className={`${
          i18next.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        <Hero />

        <DayTours />
      </main>

      <Footer />
    </>
  );
};

export default DayToursPage;
