import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  FiActivity,
  FiClock,
  FiDollarSign,
  FiGlobe,
  FiMapPin,
  FiUsers,
} from "../assets/icons/vander";
import TinySlider from "tiny-slider-react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsActions } from "redux/actions/getDetailsActions";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { arrowDown, arrowUp } from "assets/icons";

interface DetailsPageProps {}

const DetailsPage = ({}: DetailsPageProps) => {
  const { i18n, t } = useTranslation();

  const { id } = useParams();
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getDetailsActions(id, `${i18n.language}`));
  }, [id, i18n.language]);

  const { detailsLoading, details } = useSelector(
    (state: RootState) => state.getDetailsReducer
  );

  const settings = {
    container: ".tiny-five-item",
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    nav: false,
    speed: 400,
    gutter: 0,
    responsive: {
      1025: {
        items: 2,
      },

      992: {
        items: 2,
      },

      767: {
        items: 2,
      },

      425: {
        items: 1,
      },
    },
  };

  const descriptionAfterSplit = details?.overview?.split("\n");
  const includedAfterSplit = details?.included?.split("\n");
  const meet_and_pickupAfterSplit = details?.meet_and_pickup?.split("\n");

  const [showDay, setShowDay] = useState<boolean[]>([false]);

  const handleShow = (id: number) => {
    const newShowDay = [...showDay]; // Create a copy of the current state
    newShowDay[id] = !showDay[id]; // Update the specific element
    setShowDay(newShowDay); // Update the state with the new array
  };

  // console.log(showDay);

  return (
    <>
      <Header />
      <main
        className={`${
          i18n.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        {/* hero section  ****************************/}
        <Spin spinning={detailsLoading}>
          <section
            // style={{
            //   background: `url(${details?.main_image?.url})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: 'cover'
            // }}
            className="relative table w-full items-center h-[500px]  "
          >
            <div className="absolute inset-0 ">
              <img
                className="absolute  -z-20 top-0 left-0 w-full h-full"
                src={details?.main_image?.url}
                alt=""
              />
            </div>
            {/* <div className=" relative">
              <div className="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">
                  {details?.title}
                </h3>
              </div>
            </div> */}
          </section>
        </Spin>

        {/* description  *****************************/}
        <Spin spinning={detailsLoading}>
          <section className="px-[20px] lg:px-[240px] my-[100px] text-center">
            <h5 className="text-2xl font-semibold mt-5">{details?.title} </h5>
            <p className="flex items-center justify-center text-slate-400 font-medium mt-2">
              <FiMapPin className="size-4 me-1"></FiMapPin> {details?.country}
              {details?.city}
            </p>

            <ul className="list-none">
              {/* duraiton  */}
              <li className="inline-flex items-center me-5 mt-5">
                <FiClock className="size-6 stroke-[1.5] text-second_color"></FiClock>

                <div className="ms-3">
                  <p className="font-medium">{t("Duration")}</p>
                  <span className="text-slate-400 font-medium text-sm">
                    {details?.days} / {t("days")}
                  </span>
                </div>
              </li>

              {/* type  */}
              <li className="inline-flex items-center me-5 mt-5">
                <FiActivity className="size-6 stroke-[1.5] text-second_color"></FiActivity>

                <div className="ms-3">
                  <p className="font-medium">{t("Type")}</p>
                  <span className="text-slate-400 font-medium text-sm">
                    {details?.tour_type}
                  </span>
                </div>
              </li>

              <li className="inline-flex items-center me-5 mt-5">
                <FiUsers className="size-6 stroke-[1.5] text-second_color"></FiUsers>

                <div className="ms-3">
                  <p className="font-medium">{t("Group Size:")}</p>
                  <span className="text-slate-400 font-medium text-sm">10</span>
                </div>
              </li>

              <li className="inline-flex items-center me-5 mt-5">
                <FiGlobe className="size-6 stroke-[1.5] text-second_color"></FiGlobe>

                <div className="ms-3">
                  <p className="font-medium">{t("Languages")}</p>
                  <span className="text-slate-400 font-medium text-sm">
                    English
                  </span>
                </div>
              </li>

              <li className="inline-flex items-center me-5 mt-5">
                <FiDollarSign className="size-6 stroke-[1.5] text-second_color"></FiDollarSign>

                <div className="ms-3">
                  <p className="font-medium">
                    $ {details?.price} / {t("Person")}
                  </p>
                  <span className="text-slate-400 font-medium text-sm">
                    {details?.days} / days
                  </span>
                </div>
              </li>
            </ul>

            <div className="mt-12">
              <h5 className="md:text-3xl text-2xl font-semibold">
                {t("Tour Descriptions:")}
              </h5>

              {/* over view  ***********/}
              <div
                className={`text-slate-400 mt-6 ${
                  i18n.language === "ar"
                    ? "text-right flex flex-col gap-3"
                    : "text-left flex flex-col gap-3"
                } `}
              >
                <p className="text-xl font-semibold text-black">
                  {t("Overview")}
                </p>
                {descriptionAfterSplit?.map((item: any, i: number) => {
                  return <p key={i}>{item}</p>;
                })}
              </div>

              {/* What's Included **********/}
              <div
                className={`text-slate-400 mt-6 ${
                  i18n.language === "ar"
                    ? "text-right flex flex-col gap-3"
                    : "text-left flex flex-col gap-3"
                } `}
              >
                <p className="text-xl font-semibold text-black">
                  {t("What's Included")}
                </p>

                <p>
                  {includedAfterSplit?.map((item: any, i: number) => {
                    return <p key={i}>{item}</p>;
                  })}
                </p>
              </div>

              {/* Meeting and Pickup ***********/}
              <div
                className={`text-slate-400 mt-6 ${
                  i18n.language === "ar"
                    ? "text-right flex flex-col gap-3"
                    : "text-left flex flex-col gap-3"
                } `}
              >
                <p className="text-xl font-semibold text-black">
                  {t("Meeting and Pickup")}
                </p>

                <p>
                  {meet_and_pickupAfterSplit?.map((item: any, i: number) => {
                    return <p key={i}>{item}</p>;
                  })}
                </p>
              </div>

              {/* What To Expect ***************/}
              <div
                className={`text-slate-400 mt-6 ${
                  i18n.language === "ar"
                    ? "text-right flex flex-col gap-3"
                    : "text-left flex flex-col gap-3"
                } `}
              >
                <p className="text-xl font-semibold text-black">
                  {t("What To Expect")}
                </p>

                {details?.daysDetails?.map((day: any, i: number) => {
                  return (
                    <div className=" flex flex-col gap-4">
                      {/* upper title  ************/}
                      <div
                        onClick={() => handleShow(i)}
                        className="cursor-pointer flex items-center justify-between hover:p-[5px] duration-300"
                      >
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center justify-start">
                            <p className="bg-second_color text-white px-[10px] py-[6px] rounded-[8px] flex items-center justify-center font-[500]">
                              {day?.name}
                            </p>
                          </div>
                          <p className="text-black font-[600]">{day?.title}</p>
                          <p className="text-black font-[400]">
                            {day?.stops?.length} {t("Stops")}
                          </p>
                        </div>

                        <div className="flex items-center justify-center">
                          {showDay[i] ? (
                            <img
                              className="w-[30px] lg:w-[40px]"
                              src={arrowUp}
                              alt=""
                            />
                          ) : (
                            <img
                              className="w-[30px] lg:w-[40px]"
                              src={arrowDown}
                              alt=""
                            />
                          )}
                        </div>
                      </div>

                      {/* stops section ***********/}
                      {day?.stops?.map((stop: any) => {
                        return (
                          <div
                            className={`
                          ${
                            showDay[i]
                              ? "flex gap-5 h-full transition-all duration-300"
                              : "h-0 overflow-hidden"
                          }
                          `}
                          >
                            {/* order ***********/}
                            <div className="flex justify-center w-[50px]">
                              <div className="relative w-[2px] h-full bg-black ">
                                <p className="absolute top-[-5%] left-[-650%] w-[30px] h-[30px] rounded-full text-white bg-black font-[600] flex items-center justify-center border-[2px] border-white">
                                  {stop?.order}
                                </p>
                              </div>
                            </div>

                            {/* description  ******/}
                            <div className="w-full flex flex-col gap-2">
                              <p className="text-black font-[600]">
                                {stop?.name}
                              </p>
                              <p className="text-black">{stop?.description}</p>
                              <p className="font-[300]">{stop?.slug}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </Spin>

        {/* slider section  **************************/}

        <section className="px-[20px] lg:px-[140px] my-[100px] relative">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              {t("Gallery")}
            </h3>
          </div>
          <Spin spinning={detailsLoading}>
            <div className="grid grid-cols-1 relative mt-6">
              <div className="tiny-five-item" dir="ltr">
                <TinySlider settings={settings}>
                  {details?.images?.map((item: any, index: any) => {
                    return (
                      <div className="tiny-slide" key={index}>
                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 m-2">
                          <img
                            src={item?.url}
                            className="w-full h-72 object-cover "
                            alt=""
                          />
                          {/* <div className="absolute inset-0 bg-gradient-to-b to-slate-900 from-transparent opacity-0 group-hover:opacity-100 duration-500"></div> */}
                          {/* <div className="absolute p-4 bottom-0 start-0">
                          <Link
                            to=""
                            className="text-lg font-medium text-white hover:text-second_color duration-500 ease-in-out"
                          >
                            {item.place}
                          </Link>
                          <p className="text-white/70 group-hover:text-white text-sm duration-500">
                            {item.hotels}
                          </p>
                        </div> */}
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              </div>
            </div>
          </Spin>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default DetailsPage;
