import i18next from "i18next";
import Footer from "components/Footer";
import Header from "components/Header";
import Hero from "components/Hero";
import Packages from "components/Packages";

interface PackagesPageProps {}

const PackagesPage = ({}: PackagesPageProps) => {
  return (
    <>
      <Header />
      <main
        className={`${
          i18next.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        <Hero />

        <Packages />
      </main>

      <Footer />
    </>
  );
};

export default PackagesPage;
