import {
  HOME_PATH,
  ERROR_PATH,
  ABOUT_PATH,
  CONTACT_PATH,
  DETAILS_PATH,
  PACKAGES_PAGE_PATH,
  DAY_TOURS_PAGE_PATH,
  INCOMING_PATH,
  OUTGOING_PATH,
} from "Routes/paths";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import DetailsPage from "pages/DetailsPage";
import Home from "pages/Home";
import DayToursPage from "pages/DayToursPage";
import PackagesPage from "pages/PackagesPage";
import Incoming from "pages/Incoming";
import Outgoing from "pages/Outgoing";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  // {
  //   path: ERROR_PATH,
  //   element: <Error />,
  // },
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: ABOUT_PATH,
    element: <AboutUs />,
  },
  {
    path: CONTACT_PATH,
    element: <ContactUs />,
  },
  {
    path: INCOMING_PATH,
    element: <Incoming />,
  },
  {
    path: OUTGOING_PATH,
    element: <Outgoing />,
  },
  {
    path: PACKAGES_PAGE_PATH,
    element: <PackagesPage />,
  },
  {
    path: DAY_TOURS_PAGE_PATH,
    element: <DayToursPage />,
  },
  {
    path: `${DETAILS_PATH}/:id`,
    element: <DetailsPage />,
  },
];
