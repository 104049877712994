import {
  GET_TOURS_LOADING,
  GET_TOURS_SUCCESS,
  GET_TOURS_FAILURE,
} from "redux/types";

const initialState = {
  toursLoading: false,
  tours: null,
  errors: null,
};

export const getDayToursReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TOURS_LOADING:
      return { ...state, toursLoading: action.payload };
    case GET_TOURS_SUCCESS:
      return { ...state, tours: action.payload };
    case GET_TOURS_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
