import { useEffect, useState } from "react";
import {
  ar,
  en,
  fr,
  es,
  de,
  id,
  dropdownblack,
  dropdownwhite,
} from "assets/icons/index";
import { useTranslation } from "react-i18next";

interface Option {
  value: string;
  label: string;
  imgSrc: string;
}

const options: Option[] = [
  { value: "ar", label: "Ar", imgSrc: `${ar}` },
  { value: "en", label: "En", imgSrc: `${en}` },
  { value: "fr", label: "Fr", imgSrc: `${fr}` },
  { value: "es", label: "Es", imgSrc: `${es}` },
  { value: "de", label: "De", imgSrc: `${de}` },
  { value: "id", label: "Id", imgSrc: `${id}` },
];

const LangMenu = () => {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options?.find((language: any) => i18n.language === language?.value)
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // handle the header background effect
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollHeight(window.scrollY);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setScrollHeight(window.scrollY);
      });
    };
  }, []);

  const handleChangeLanguage = (language: any) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);

    const direction = language === "ar" ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", direction);
    localStorage.setItem("dir", direction);

    window.location.reload();
  };

  return (
    <div className="relative inline-block w-[80px]">
      <div
        className="border border-gray-300 rounded-md p-1 cursor-pointer font-[500]"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? (
          <div className="flex items-center gap-2">
            <img
              src={selectedOption?.imgSrc}
              alt={selectedOption?.label}
              className="w-6 h-6 mr-2"
            />
            <span
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-[#ffffff80] hover:text-white duration-300"
              } flex items-center `}
            >
              {selectedOption?.label}
            </span>
          </div>
        ) : (
          <span
            className={`${
              scrollHeight >= 50
                ? "text-black hover:text-second_color duration-300"
                : "text-[#ffffff80] hover:text-white duration-300"
            } flex items-center gap-1`}
          >
            Lang
            {scrollHeight >= 50 ? (
              <img className="w-[30px]" src={dropdownblack} alt="" />
            ) : (
              <img className="w-[30px]" src={dropdownwhite} alt="" />
            )}
          </span>
        )}
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full bg-white/60 border border-gray-300 rounded-md shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              className="flex items-center p-2 hover:bg-gray-300 cursor-pointer gap-2"
              onClick={() => {
                handleSelect(option);
                handleChangeLanguage(option.value);
              }}
            >
              <img
                src={option.imgSrc}
                alt={option.label}
                className="w-6 h-6 mr-2"
              />
              <span className="font-[500]">{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LangMenu;
