import Header from "components/Header";
import About from "components/About";
import { Link } from "react-router-dom";
import Clients from "components/Clients";
import Places from "components/Places";
import Footer from "components/Footer";
import { teamData } from "data/data";

import about from "../assets/images/about.jpg";
import map from "../assets/images/map-plane-big.png";

import CountUp from "react-countup";
import { FiGlobe, FiUsers } from "react-icons/fi";
import { ABOUT_PATH } from "Routes/paths";
import { useTranslation } from "react-i18next";

interface AboutUsProps {}

const AboutUs = ({}: AboutUsProps) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Header />
      <main
        className={`${
          i18n.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        {/* hero section  **********/}
        <section className="relative table w-full items-center py-36 bg-hero4 bg-top bg-no-repeat bg-cover">
          <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
          <div className=" relative">
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">
                ONE PANORAMA TOURS
              </h3>
            </div>
          </div>
        </section>

        {/* about setion  *******************/}
        <div className="px-[20px] lg:px-[140px]  my-[100px] relative">
          <div className="relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6 relative">
              <div className="md:col-span-5">
                <div className="relative">
                  <img
                    src={about}
                    className="mx-auto rounded-3xl shadow dark:shadow-gray-700 w-[90%]"
                    alt=""
                  />

                  <div className="absolute flex items-center bottom-16 md:-start-10 -start-5 p-2 2xl:p-4 rounded-lg shadow-md  bg-white  w-56 m-3">
                    <div className="flex items-center justify-center h-[45px] min-w-[65px] bg-second_color/5 text-second_color text-center rounded-xl me-3">
                      <FiUsers className="size-6"></FiUsers>
                    </div>
                    <div className="flex-1">
                      <span className="text-slate-400">{t("Visitor")}</span>
                      <p className="text-xl font-bold">
                        <CountUp
                          className="counter-value"
                          start={0}
                          end={4589}
                        />
                      </p>
                    </div>
                  </div>

                  <div className="absolute flex items-center top-16 md:-end-10 -end-5 p-2 2xl:p-4 rounded-lg shadow-md  bg-white  w-60 m-3">
                    <div className="flex items-center justify-center h-[45px] min-w-[65px] bg-second_color/5 text-second_color text-center rounded-xl me-3">
                      <FiGlobe className="size-6"></FiGlobe>
                    </div>
                    <div className="flex-1">
                      <span className="text-slate-400">
                        {t("Travel Packages")}
                      </span>
                      <p className="text-xl font-bold">
                        <CountUp className="counter-value" start={0} end={50} />
                        +
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:col-span-7">
                <div className="lg:ms-8">
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                    {t("World Best Travel")} <br /> {t("Agency:")}
                    <span className="text-[#36b8cd]">ONE PANORAMA TOURS</span>
                  </h3>

                  <p className="text-slate-400 max-w-xl mb-6">
                    {t(
                      "Get instant helpful resources about anything on the go, easily implement secure money transfer solutions, boost your daily efficiency, connect to other app users and create your own Travosy network, and much more with just a few taps. commodo consequat. Duis aute irure."
                    )}
                  </p>
                </div>
              </div>

              <div className="absolute bottom-0 start-1/3 -z-20">
                <img src={map} className="lg:w-[600px] w-96" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* our team  **********************/}
        <div className="px-[20px] lg:px-[140px] relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              {t("Our Team")}
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">
              This is just a simple text made for this unique and awesome
              template, you can replace it with any text.
            </p> */}
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
            {teamData.map((item, index) => {
              return (
                <div className="lg:col-span-3 md:col-span-6" key={index}>
                  <div className="group text-center">
                    <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                      <img src={item.image} className="" alt="" />
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-60 duration-500"></div>
                    </div>

                    <div className="content mt-3">
                      <Link
                        to=""
                        className="text-lg font-semibold hover:text-second_color duration-500"
                      >
                        {item.name}
                      </Link>
                      <p className="text-slate-400">{item.possition}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Clients />

        <Places />
      </main>

      <Footer />
    </>
  );
};

export default AboutUs;
