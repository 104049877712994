import React from "react";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
import { whitelogo } from "assets/icons";
import { headerSocial } from "data/data";
import {
  ABOUT_PATH,
  CONTACT_PATH,
  DAY_TOURS_PAGE_PATH,
  PACKAGES_PAGE_PATH,
} from "Routes/paths";
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface FooterProps {}

const Footer = ({}: FooterProps) => {
  const { i18n, t } = useTranslation();
  return (
    <footer className="px-[20px] lg:px-[140px] bg-first_color relative text-gray-200 dark:text-gray-200">
      <div className=" relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                <div className="lg:col-span-3 md:col-span-12">
                  <Link to="#" className="text-[22px] focus:outline-none">
                    <img src={whitelogo} alt="" />
                  </Link>
                  <p className="mt-6 text-gray-300">
                    {t(
                      "Planning for a trip? We will organize your trip with the best places and within best budget!"
                    )}
                  </p>
                  <ul className="list-none mt-6 space-x-1">
                    {headerSocial.map((item, index) => {
                      let Icon = item.icon;
                      return (
                        <li className="inline" key={index}>
                          <Link
                            to={item.link}
                            target="_blank"
                            className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 rounded-md hover:bg-second_color hover:text-white text-white"
                          >
                            <Icon
                              className="size-4 align-middle"
                              title="Buy Now"
                            ></Icon>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <div className="lg:ms-8">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {t("Office")}
                    </h5>
                    <h5 className="tracking-[1px] text-gray-100 mt-6">
                      ONE PANORAMA TOURS
                    </h5>

                    <div className="flex mt-4">
                      <FiMapPin className="size-4 text-second_color me-2 mt-1"></FiMapPin>
                      <div className="">
                        <h6 className="text-gray-300">
                          13 Kasr ElNil, St. Downtown. Cairo
                        </h6>
                      </div>
                    </div>

                    <div className="flex mt-4">
                      <FiMail className="size-4 text-second_color me-2 mt-1"></FiMail>
                      <div className="">
                        <Link
                          to="mailto:info@onepanorama.com"
                          className="text-white hover:text-slate-400 duration-500 ease-in-out"
                        >
                          info@onepanorama.com
                        </Link>
                      </div>
                    </div>

                    <div className="flex mt-4">
                      <FiPhone className="size-4 text-second_color me-2 mt-1"></FiPhone>
                      <div className="">
                        <Link
                          to="tel:+201008505071"
                          className="text-white hover:text-slate-400 duration-500 ease-in-out"
                        >
                          +201008505071
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <div className="lg:ms-8">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      {t("Company")}
                    </h5>
                    <div className="mt-6 flex flex-col gap-3 font-[500]">
                      <Link
                        to={"/"}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("Home")}
                      </Link>
                      <Link
                        to={`${ABOUT_PATH}`}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("About Us")}
                      </Link>
                      <Link
                        to={`${CONTACT_PATH}`}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("Contact Us")}
                      </Link>
                      <Link
                        to={`${DAY_TOURS_PAGE_PATH}`}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("Day tours")}
                      </Link>
                      <Link
                        to={`${PACKAGES_PAGE_PATH}`}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("Packages")}
                      </Link>
                      {/* <Link
                        to={""}
                        className="text-white hover:text-[#ffffff80] duration-300"
                      >
                        {t("Trips")}
                      </Link> */}
                    </div>
                  </div>
                </div>

                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Whats app
                  </h5>
                  <p className="mt-6">
                    {t("You can contact with us via whats app")}
                  </p>

                  <div className="grid grid-cols-1">
                    {/* <div className="my-3">
                        <label className="form-label">
                          Write your email{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <FiMail className="size-4 absolute top-3 start-4"></FiMail>
                          <input
                            type="email"
                            className="ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200 outline-none"
                            placeholder="Email"
                            name="email"
                            // required=""
                          />
                        </div>
                      </div> */}

                    <Link
                      to="https://wa.me/+201008505071"
                      className="mt-[10px] py-2 px-5  font-semibold tracking-wide align-middle duration-500 text-base text-center bg-second_color text-white rounded-md flex items-center justify-center gap-2"
                    >
                      <FaWhatsapp className="text-[20px]"></FaWhatsapp> Whats
                      app
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} ONE PANORAMA TOURS .
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
