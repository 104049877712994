import i18next from "i18next";
import Footer from "components/Footer";
import Header from "components/Header";
import Hero from "components/Hero";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { getDayToursActions } from "redux/actions/getDayToursActions";
import { Spin } from "antd";
import { DETAILS_PATH } from "Routes/paths";
import { useTranslation } from "react-i18next";

interface IncomingProps {}

const Incoming = ({}: IncomingProps) => {
  const { i18n, t } = useTranslation();
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getDayToursActions(`${i18n.language}`, "tour_type=incoming"));
  }, [i18n.language]);

  const { toursLoading, tours } = useSelector(
    (state: RootState) => state.getDayToursReducer
  );

  const settings = {
    container: ".tiny-five-item",
    // controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: ["", ""],
    nav: false,
    speed: 400,
    gutter: 0,
    responsive: {
      1025: {
        items: 5,
      },

      992: {
        items: 4,
      },

      767: {
        items: 3,
      },

      425: {
        items: 1,
      },
    },
  };

  // console.log(tours);
  return (
    <>
      <Header />
      <main
        className={`${
          i18next.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        <Hero />

        <div className="px-[20px] lg:px-[140px]  my-[100px] relative" dir="ltr">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
              {t("Incoming Trips")}
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              {t(
                "Planning for a trip? We will organize your trip with the best places and within best budget!"
              )}
            </p>
          </div>

          <Spin spinning={toursLoading}>
            <div className="grid grid-cols-1 relative mt-6">
              <div className="tiny-five-item">
                <TinySlider settings={settings}>
                  {tours?.map((item: any, index: any) => {
                    return (
                      <div className="tiny-slide" key={index}>
                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 m-2">
                          <img
                            src={item?.main_image?.url}
                            className="w-full h-72 object-cover scale-125 group-hover:scale-100 duration-500"
                            alt=""
                          />
                          <div className="absolute inset-0 bg-gradient-to-b to-slate-900 from-transparent opacity-55 group-hover:opacity-100 duration-500"></div>
                          <div className="absolute p-4 bottom-0 start-0">
                            <Link
                              to={`${DETAILS_PATH}/${item?.id}`}
                              className="text-lg font-medium text-white hover:text-second_color duration-500 ease-in-out line-clamp-2 text-ellipsis"
                            >
                              {item?.title}
                            </Link>
                            <div className="flex items-center gap-4">
                              <p className="text-white/70 group-hover:text-white text-sm duration-500">
                                {item?.price} $
                              </p>
                              <p className="text-white/70 group-hover:text-white text-sm duration-500">
                                {item?.country}, {item?.city}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              </div>
            </div>
          </Spin>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Incoming;
