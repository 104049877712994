import { Link } from "react-router-dom";
import { closeicon, darklogo, whitelogo } from "../../assets/icons";
import {
  ABOUT_PATH,
  CONTACT_PATH,
  DAY_TOURS_PAGE_PATH,
  INCOMING_PATH,
  OUTGOING_PATH,
  PACKAGES_PAGE_PATH,
} from "../../Routes/paths";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface MobileMenuProps {
  showMobileMenu: boolean;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu = ({ showMobileMenu, setShowMobileMenu }: MobileMenuProps) => {
  const { i18n, t } = useTranslation();

  const [showTrips, setShowTrips] = useState(false);
  return (
    <div
      className={
        showMobileMenu
          ? "absolute top-0 left-0 ease-linear transition-all duration-300 px-[30px] py-[40px] w-full h-screen backdrop-blur-xl bg-black/95 flex flex-col gap-8 font-Poppins"
          : "absolute top-0 left-[-100%] ease-linear transition-all duration-300 px-[30px] py-[40px] w-full h-screen backdrop-blur-xl bg-black/95 flex flex-col gap-8 font-Poppins"
      }
    >
      {/* logo and close icon section  *********************/}
      <div className="flex items-center justify-between">
        <img className="w-[200px]" src={whitelogo} alt="" />

        <img onClick={() => setShowMobileMenu(false)} src={closeicon} alt="" />
      </div>

      <div
        className="pb-[20px] text-white
       text-[16px] font-[500] flex flex-col gap-8 "
      >
        <Link to={"/"}>{t("Home")}</Link>
        <Link to={`${ABOUT_PATH}`}>{t("About Us")}</Link>
        <Link to={`${CONTACT_PATH}`}> {t("Contact Us")}</Link>
        <Link to={`${DAY_TOURS_PAGE_PATH}`}> {t("Day tours")}</Link>
        <Link to={`${PACKAGES_PAGE_PATH}`}>{t("Packages")}</Link>
        <div className="flex flex-col gap-1">
          <p onClick={() => setShowTrips(!showTrips)}>- {t("Trips")}</p>

          {showTrips && (
            <div
              className={`${
                i18n.language === "ar"
                  ? "flex flex-col gap-2 pr-[20px]"
                  : "flex flex-col gap-2 pl-[20px]"
              }`}
            >
              <Link to={`${INCOMING_PATH}`}>{t("Incoming")}</Link>

              <Link to={`${OUTGOING_PATH}`}>{t("Outgoing")}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
