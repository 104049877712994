import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { quote, start } from "../assets/icons";
import { ClientData } from "data/data";

import {
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
} from "assets/images";
import { useTranslation } from "react-i18next";

interface ClientsProps {}

const Clients = ({}: ClientsProps) => {
  const { i18n, t } = useTranslation();
  const settings = {
    container: ".tiny-three-item",
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };
  return (
    <div
      className="px-[20px] lg:px-[140px]  my-[100px] relative md:mt-24 mt-16"
      dir="ltr"
    >
      <div className="grid grid-cols-1 pb-6 text-center">
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          {t("What Our Users Say")}
        </h3>

        {/* <p className="text-slate-400 max-w-xl mx-auto">
          This is just a simple text made for this unique and awesome template,
          you can replace it with any text.
        </p> */}
      </div>

      <div className="grid grid-cols-1 mt-6">
        <div className="tiny-three-item">
          <TinySlider settings={settings}>
            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Amazing service and unforgettable memories. Highly recommend!"
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client1}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Calvin Carlo</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>

            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Perfectly planned trip, exceeded all our expectations!"
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client2}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Christa Smith</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>

            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Fantastic experience, friendly guides, and stunning locations."
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client3}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Jemina CLone</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>

            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Seamless travel experience from start to finish. Loved it!"
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client1}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Smith Vodka</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>

            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Incredible tours with top-notch service. Will book again!"
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client2}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Sara Lopez</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>

            {/* client  */}
            <div className="tiny-slide text-center">
              <div className="cursor-e-resize">
                <div className="content relative rounded shadow  m-2 p-6 bg-white  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white  before:border-b-white  before:border-s-transparent before:shadow-testi  before:origin-top-left">
                  <div className="flex items-center justify-center my-[50px]">
                    <img className="w-[40px]" src={quote} alt="" />
                  </div>
                  <p className="text-slate-400">
                    {t(
                      "Professional, fun, and absolutely worth it. Best trip ever!"
                    )}
                  </p>
                  <div className="my-[10px] flex items-center justify-center  gap-3">
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                    <img className="w-[15px]" src={start} alt="" />
                  </div>
                </div>

                <div className="text-center mt-5">
                  <img
                    src={client1}
                    className="size-14 rounded-full shadow-md  mx-auto"
                    alt=""
                  />
                  <h6 className="mt-2 font-semibold">Cristino Murfi</h6>
                  {/* <span className="text-slate-400 text-sm">
                        {item.possition}
                      </span> */}
                </div>
              </div>
            </div>
          </TinySlider>
        </div>
      </div>
    </div>
  );
};

export default Clients;
