import {
  GET_PACKAGES_LOADING,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
} from "../types/index";

const initialState = {
  packagesLoading: false,
  packages: null,
  errors: null,
};

export const getPackagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PACKAGES_LOADING:
      return { ...state, packagesLoading: action.payload };
    case GET_PACKAGES_SUCCESS:
      return { ...state, packages: action.payload };
    case GET_PACKAGES_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
