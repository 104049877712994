import api from "api";

export const listItemsService = async (
  url: string,
  params?: string,
  lang?: any
) => {
  const response = await api.get(url + `?${params}`, {
    headers: {
      // "Authorization" : `Bearer ${store.getState()?.loginReducer?.token}`

      "Accept-Language": `${lang}`,
    },
  });

  return response;
};
