import About from "../components/About";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Places from "../components/Places";
import DayTours from "../components/DayTours";
import Packages from "../components/Packages";
import { useTranslation } from "react-i18next";

interface HomeProps {}

const Home = ({}: HomeProps) => {
  const { i18n } = useTranslation();
  return (
    <>
      <Header />
      <main
        className={`${
          i18n.language === "ar" ? "font-Almarai" : "font-Poppins"
        } w-full`}
      >
        <Hero />

        <DayTours />

        <About />

        <Packages />

        <Clients />

        <Places />
      </main>

      <Footer />
    </>
  );
};

export default Home;
