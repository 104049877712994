import { useEffect, useState } from "react";
import { FiClock, FiMail, FiMapPin } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { darklogo, mobilemenu, whitelogo } from "assets/icons";
import { headerSocial } from "data/data";
import {
  ABOUT_PATH,
  CONTACT_PATH,
  DAY_TOURS_PAGE_PATH,
  HOME_PATH,
  INCOMING_PATH,
  OUTGOING_PATH,
  PACKAGES_PAGE_PATH,
} from "Routes/paths";
import MobileMenu from "./mobile/MobileMenu";
import LangMenu from "./LangMenu";
import { useTranslation } from "react-i18next";

interface HeaderProps {}

const Header = ({}: HeaderProps) => {
  const { i18n, t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const [showTrips, setShowTrips] = useState(false);

  // handle the header background effect
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollHeight(window.scrollY);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setScrollHeight(window.scrollY);
      });
    };
  }, []);
  return (
    <section>
      {/* upper section  */}
      <div className="font-Poppins hidden w-full h-[50px] bg-first_color text-white lg:flex items-center justify-center">
        <div className="min-w-[1024px] max-w-[1024px] flex items-center justify-between">
          <div className="flex items-center gap-5">
            <p className="flex items-center gap-2">
              <FiClock className="text-second_color size-4"></FiClock> Sat-Thu:
              10 am to 6 pm
            </p>
            <p className="flex items-center gap-2">
              {" "}
              <FiMapPin className="text-second_color size-4"></FiMapPin> 13 Kasr
              ElNil, St. Downtown. Cairo
            </p>
          </div>

          <div className="flex items-center gap-4">
            <Link
              to="mailto:info@onepanorama.com"
              className="flex items-center gap-2"
            >
              <FiMail className="text-second_color size-4"></FiMail>{" "}
              info@onepanorama.com
            </Link>
            <div className="flex items-center gap-2">
              {headerSocial?.map((item, index) => {
                let Icon = item.icon;
                return (
                  <li className="inline" key={index}>
                    <Link
                      to={item.link}
                      target="_blank"
                      className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 rounded-md hover:bg-second_color hover:text-white text-slate-300"
                    >
                      <Icon className="size-4 align-middle"></Icon>
                    </Link>
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* nav bar  */}
      <div
        className={`${
          scrollHeight >= 50
            ? " lg:mt-[-50px] backdrop-blur-xl bg-white/80 "
            : "backdrop-blur-xl bg-black/30"
        } fixed z-50 w-full  lg:h-[90px] lg:flex items-center justify-center p-4 lg:p-0`}
      >
        <div className="lg:min-w-[1024px] lg:max-w-[1024px] flex items-center justify-between">
          {/* logo ************/}
          {scrollHeight >= 50 ? (
            <img
              onClick={() => navigate(`${HOME_PATH}`)}
              className="w-[200px] lg:w-[260px] cursor-pointer"
              src={darklogo}
              alt=""
            />
          ) : (
            <img
              onClick={() => navigate(`${HOME_PATH}`)}
              className="w-[200px] lg:w-[260px] cursor-pointer"
              src={whitelogo}
              alt=""
            />
          )}

          {/* nav links  ********/}
          <div className="hidden lg:flex items-center gap-4 font-[500]">
            <Link
              to={"/"}
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-white hover:text-[#ffffff80] duration-300"
              }`}
            >
              {t("Home")}
            </Link>
            <Link
              to={`${ABOUT_PATH}`}
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-white hover:text-[#ffffff80] duration-300"
              }`}
            >
              {t("About Us")}
            </Link>
            <Link
              to={`${CONTACT_PATH}`}
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-white hover:text-[#ffffff80] duration-300"
              }`}
            >
              {t("Contact Us")}
            </Link>
            <Link
              to={`${DAY_TOURS_PAGE_PATH}`}
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-white hover:text-[#ffffff80] duration-300"
              }`}
            >
              {t("Day tours")}
            </Link>
            <Link
              to={`${PACKAGES_PAGE_PATH}`}
              className={`${
                scrollHeight >= 50
                  ? "text-black hover:text-second_color duration-300"
                  : "text-white hover:text-[#ffffff80] duration-300"
              }`}
            >
              {t("Packages")}
            </Link>
            <div className="relative">
              <Link
                onClick={() => setShowTrips(!showTrips)}
                to={""}
                className={`${
                  scrollHeight >= 50
                    ? "text-black hover:text-second_color duration-300"
                    : "text-white hover:text-[#ffffff80] duration-300"
                }`}
              >
                {t("Trips")}
              </Link>

              {showTrips && (
                <div
                  className={`${
                    scrollHeight >= 50
                      ? "absolute top-[165%] left-[-75%] p-[20px] rounded-[15px] bg-white shadow-2xl text-black flex flex-col gap-2 z-50"
                      : "absolute top-[165%] left-[-75%] p-[20px] rounded-[15px] bg-white text-black flex flex-col gap-2 z-50"
                  }`}
                >
                  <Link
                    to={`${INCOMING_PATH}`}
                    className="text-sm text-black hover:text-second_color duration-300"
                  >
                    {t("Incoming")}
                  </Link>

                  <Link
                    to={`${OUTGOING_PATH}`}
                    className="text-sm text-black hover:text-second_color duration-300"
                  >
                    {t("Outgoing")}
                  </Link>
                </div>
              )}
            </div>
          </div>

          <LangMenu />

          <div className="block lg:hidden">
            <img
              onClick={() => setShowMobileMenu(true)}
              className="w-[30px] mr-[10px] mt-[5px]"
              src={mobilemenu}
              alt=""
            />
          </div>

          <MobileMenu
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        </div>
      </div>
    </section>
  );
};

export default Header;
