import {
  GET_DETAILS_LOADING,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const getDetailsActions =
  (id: any, lang?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_DETAILS_LOADING, payload: true });
      const response = await listSingleItemService(
        "https://admin.onepanorama.com/api/tours/",
        `${id}`,
        `${lang}`
      );

      dispatch({ type: GET_DETAILS_SUCCESS, payload: response.data.data });
    } catch (err) {
      dispatch({ type: GET_DETAILS_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_DETAILS_LOADING, payload: false });
    }
  };
