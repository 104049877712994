import {
  GET_DETAILS_LOADING,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_FAILURE,
} from "redux/types";

const initialState = {
  detailsLoading: false,
  details: null,
  erros: null,
};

export const getDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DETAILS_LOADING:
      return { ...state, detailsLoading: action.payload };
    case GET_DETAILS_SUCCESS:
      return { ...state, details: action.payload };
    case GET_DETAILS_FAILURE:
      return { ...state, erros: action.payload };
    default:
      return state;
  }
};
