import {
  GET_TOURS_LOADING,
  GET_TOURS_SUCCESS,
  GET_TOURS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const getDayToursActions =
  (lang?: any, type?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_TOURS_LOADING, payload: true });

      const response = await listItemsService(
        "https://admin.onepanorama.com/api/tours",
        `program_type=day_tour&${type}`,
        `${lang}`
      );

      dispatch({ type: GET_TOURS_SUCCESS, payload: response.data.data });
    } catch (err) {
      dispatch({ type: GET_TOURS_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_TOURS_LOADING, payload: false });
    }
  };
