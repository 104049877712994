import {
  GET_PACKAGES_LOADING,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
} from "../types/index";

import { Dispatch } from "redux";

import { listItemsService } from "../../services/listItemsService";

export const getPackagesActions =
  (lang?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_PACKAGES_LOADING, payload: true });
      const response = await listItemsService(
        "https://admin.onepanorama.com/api/tours",
        "program_type=package",
        `${lang}`
      );

      dispatch({ type: GET_PACKAGES_SUCCESS, payload: response.data.data });
    } catch (err) {
      dispatch({ type: GET_PACKAGES_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_PACKAGES_LOADING, payload: false });
    }
  };
