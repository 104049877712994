import api from "api";
import store from "redux/store";

export const listSingleItemService = async (
  url: string,
  id: string,
  lang?: any
) => {
  const response = await api.get(url + id, {
    headers: {
      // "Authorization" : `Bearer ${store?.getState()?.loginReducer?.token}`
      "Accept-Language": `${lang}`,
    },
  });

  return response;
};
