import { Link } from "react-router-dom";
import { FiInstagram, FiMapPin } from "../assets/icons/vander";
import { DETAILS_PATH } from "../Routes/paths";
import { useEffect, useState } from "react";
import { RootState } from "redux/rootReducer";
import { start } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesActions } from "redux/actions/getPackagesActions";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

interface PackagesProps {}

const Packages = ({}: PackagesProps) => {
  const [itemsToView, setItemsToView] = useState(4);
  const { i18n, t } = useTranslation();
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getPackagesActions(`${i18n.language}`));
  }, []);

  const { packagesLoading, packages } = useSelector(
    (state: RootState) => state.getPackagesReducer
  );

  // console.log(itemsToView);

  return (
    <div
      id="packages"
      className="px-[20px] lg:px-[140px]  my-[100px] relative md:mt-24 mt-16"
    >
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
          {t("Packages")}
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          {t(
            "Planning for a trip? We will organize your trip with the best places and within best budget!"
          )}
        </p>
      </div>

      <Spin spinning={packagesLoading}>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {packages?.slice(0, itemsToView)?.map((item: any, index: any) => {
            return (
              <div className="group rounded-md shadow" key={index}>
                <div className="relative overflow-hidden rounded-md shadow mx-2 mt-2">
                  <img
                    src={item?.main_image?.url}
                    className="min-h-[160px] max-h-[160px] w-full  scale-125 group-hover:scale-100 duration-500"
                    alt=""
                  />
                </div>

                <div className="p-3">
                  <p className="flex items-center text-slate-400 font-medium mb-2">
                    <FiMapPin className="text-second_color size-4 me-1"></FiMapPin>{" "}
                    {item?.country}, {item?.city}
                  </p>
                  <Link
                    to={`${DETAILS_PATH}/${item?.id}`}
                    className="text-lg font-medium hover:text-second_color duration-500 ease-in-out line-clamp-1 text-ellipsis"
                  >
                    {item.title}
                  </Link>

                  <div className="flex items-center mt-2 gap-3">
                    <span className="text-slate-400">{t("Rating:")}</span>
                    <div className="text-lg font-medium flex items-center gap-2">
                      {item?.rating === 0 ? (
                        ""
                      ) : item?.rating === 1 ? (
                        <img className="w-[15px]" src={start} alt="" />
                      ) : item?.rating === 2 ? (
                        <div className="flex items-center gap-1">
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                        </div>
                      ) : item?.rating === 3 ? (
                        <div className="flex items-center gap-1">
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                        </div>
                      ) : item?.rating === 4 ? (
                        <div className="flex items-center gap-1">
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                        </div>
                      ) : item?.rating === 5 ? (
                        <div className="flex items-center gap-1">
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                          <img className="w-[15px]" src={start} alt="" />
                        </div>
                      ) : (
                        ""
                      )}

                      <p className="inline text-black text-sm">
                        &#40; {`${item?.rating}`} &#41;
                      </p>
                    </div>
                  </div>

                  <div className="mt-3 pt-3 flex justify-between items-center border-t border-slate-100 ">
                    <h5 className="text-lg font-medium text-second_color">
                      {item?.price} $- {item?.days} {t("days")} /{" "}
                      {item?.days - 1} {t("nights")}
                    </h5>

                    <Link
                      to={`${DETAILS_PATH}/${item?.id}`}
                      className="text-slate-400 hover:text-second_color"
                    >
                      {t("Explore Now")}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {itemsToView >= packages?.length ? (
          ""
        ) : (
          <div className="my-[20px] w-full flex items-center justify-center">
            <button
              onClick={() => setItemsToView((prev: any) => prev + 4)}
              className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-second_color text-white rounded-md"
            >
              {t("Load more")}
              <i className="mdi mdi-chevron-right align-middle ms-0.5"></i>
            </button>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default Packages;
