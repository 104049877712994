import { combineReducers } from "redux";
import { getPackagesReducer } from "./reducers/getPackagesReducer";
import { getDayToursReducer } from "./reducers/getDayToursRducer";
import { getDetailsReducer } from "./reducers/getDetailsReducer";

export const appReducer = combineReducers({
  getPackagesReducer,
  getDayToursReducer,
  getDetailsReducer,
});

export const rootReducers = (state: any, action: any) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
