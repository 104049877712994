import listing1 from "../assets/images/listing/1.jpg";
import listing2 from "../assets/images/listing/2.jpg";
import listing3 from "../assets/images/listing/3.jpg";
import listing4 from "../assets/images/listing/4.jpg";
import listing5 from "../assets/images/listing/5.jpg";
import listing6 from "../assets/images/listing/6.jpg";
import listing7 from "../assets/images/listing/7.jpg";
import listing8 from "../assets/images/listing/8.jpg";
import listing9 from "../assets/images/listing/9.jpg";
import listing10 from "../assets/images/listing/10.jpg";
import listing11 from "../assets/images/listing/11.jpg";
import listing12 from "../assets/images/listing/12.jpg";

import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import client4 from "../assets/images/client/04.jpg";
import client5 from "../assets/images/client/05.jpg";
import client6 from "../assets/images/client/06.jpg";
import client7 from "../assets/images/client/07.jpg";

// import blog1 from '../assets/images/blog/1.jpg'
// import blog2 from '../assets/images/blog/2.jpg'
// import blog3 from '../assets/images/blog/3.jpg'
// import blog4 from '../assets/images/blog/4.jpg'
// import blog5 from '../assets/images/blog/5.jpg'
// import blog6 from '../assets/images/blog/6.jpg'
// import blog7 from '../assets/images/blog/7.jpg'
// import blog8 from '../assets/images/blog/8.jpg'
// import blog9 from '../assets/images/blog/9.jpg'

// import payment1 from '../assets/images/payments/visa.jpg'
// import payment2 from '../assets/images/payments/american-express.jpg'
// import payment3 from '../assets/images/payments/discover.jpg'
// import payment4 from '../assets/images/payments/mastercard.jpg'

import {
  FiShoppingCart,
  FiDribbble,
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMail,
  FiClock,
  FiActivity,
  FiUsers,
  FiGlobe,
  FiDollarSign,
  FiYoutube,
  FiHelpCircle,
  FiBookmark,
  FiSettings,
  FiGithub,
  FiGitlab,
  FiPhone,
  RiTiktokLine,
  FaWhatsapp,
} from "../assets/icons/vander";

export const topDestination = [
  {
    image: listing1,
    place: "Rome, Italy",
    hotels: "3 Hotels",
  },
  {
    image: listing2,
    place: "Singapore",
    hotels: "3 Hotels",
  },
  {
    image: listing3,
    place: "Paris, France",
    hotels: "3 Hotels",
  },
  {
    image: listing4,
    place: "Goa, India",
    hotels: "3 Hotels",
  },
  {
    image: listing5,
    place: "Whistler, Canada",
    hotels: "3 Hotels",
  },
  {
    image: listing6,
    place: "Lumpur, Malaysia",
    hotels: "3 Hotels",
  },
  {
    image: listing7,
    place: "Sydney, Australia",
    hotels: "3 Hotels",
  },
  {
    image: listing8,
    place: "Virginia Beach",
    hotels: "3 Hotels",
  },
];

export const packages = [
  {
    id: 1,
    image: listing1,
    tagText: "10% Off",
    place: "Dubai",
    title: "Cuba Sailing Adventure",
    amount: "$ 58 / Day",
  },
  {
    id: 2,
    image: listing2,
    place: "Italy",
    title: "Tour in New York",
    amount: "$ 58 / Day",
  },
  {
    id: 3,
    image: listing3,
    place: "Maldivas",
    title: "Discover Greece",
    amount: "$ 58 / Day",
  },
  {
    id: 4,
    image: listing4,
    place: "USA",
    title: "Museum of Modern Art",
    amount: "$ 58 / Day",
  },
  {
    id: 5,
    image: listing5,
    place: "Bali",
    title: "Peek Mountain View",
    amount: "$ 58 / Day",
  },
  {
    id: 6,
    image: listing6,
    tagText: "25% Off",
    place: "Bangkok",
    title: "Hot Baloon Journey",
    amount: "$ 58 / Day",
  },
  {
    id: 7,
    image: listing7,
    place: "Singapore",
    title: "Orca Camp Kayaking Trip",
    amount: "$ 58 / Day",
  },
  {
    id: 8,
    image: listing8,
    tagText: "20% Off",
    place: "Thailand",
    title: "Caño Cristales River Trip",
    amount: "$ 58 / Day",
  },
];

export const ClientData = [
  {
    image: client1,
    desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "',
    name: "Calvin Carlo",
    possition: "Manager",
  },
  {
    image: client2,
    desc: '"The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century."',
    name: "Christa Smith",
    possition: "Manager",
  },
  {
    image: client3,
    desc: '" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "',
    name: "Jemina CLone",
    possition: "Manager",
  },
  {
    image: client4,
    desc: '" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "',
    name: "Smith Vodka",
    possition: "Manager",
  },
  {
    image: client5,
    desc: '" There is now an abundance of readable dummy texts. These are usually used when a text is required. "',
    name: "Cristino Murfi",
    possition: "Manager",
  },
  {
    image: client6,
    desc: '" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "',
    name: "Cristino Murfi",
    possition: "Manager",
  },
];

export const placeImage = [
  listing1,
  listing2,
  listing3,
  listing4,
  listing5,
  listing6,
  listing7,
  listing8,
  listing9,
  listing10,
  listing11,
  listing2,
  listing3,
  listing4,
];

// export const footerSocial = [
//   {
//     icon: FiShoppingCart,
//     link: "https://1.envato.market/travosy-react",
//   },
//   {
//     icon: FiDribbble,
//     link: "https://dribbble.com/shreethemes",
//   },
//   {
//     icon: FiLinkedin,
//     link: "http://linkedin.com/company/shreethemes",
//   },
//   {
//     icon: FiFacebook,
//     link: "https://www.facebook.com/shreethemes",
//   },
//   {
//     icon: FiInstagram,
//     link: "https://www.instagram.com/shreethemes",
//   },
//   {
//     icon: FiTwitter,
//     link: "https://twitter.com/shreethemes",
//   },
//   {
//     icon: FiMail,
//     link: "mailto:support@shreethemes.in",
//   },
// ];

export const footerCompany = [
  {
    name: "About us",
    link: "/aboutus",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Team",
    link: "/team",
  },
  {
    name: "Pricing",
    link: "/pricing",
  },
  {
    name: "Blog",
    link: "/blogs",
  },
  {
    name: " Login",
    link: "/login",
  },
];

export const headerSocial = [
  {
    icon: FiFacebook,
    link: "https://www.facebook.com/Onepanorama?mibextid=LQQJ4d",
  },
  {
    icon: FiInstagram,
    link: "https://www.instagram.com/one.panoramatours?igsh=NXVyN2FkNWpzazk0&utm_source=qr",
  },

  {
    icon: RiTiktokLine,
    link: "https://www.tiktok.com/@onepanorama1?_t=8ofzH5WkLnb&_r=1",
  },
  {
    icon: FaWhatsapp,
    link: "https://wa.me/+201008505071",
  },
];

export const teamData = [
  {
    image: client5,
    name: "Tarek Mohamed",
    possition: "chairman",
  },
  {
    image: client4,
    name: "Mohamed Tarek",
    possition: "Deputy chairman",
  },

  {
    image: client6,
    name: "Sherif Bu Eash",
    possition: "General manager",
  },
  {
    image: client7,
    name: "Fatma Magdy",
    possition: "Operation manager",
  },
];

export const tourDetailAbout = [
  {
    icon: FiClock,
    name: "Duration",
    title: "2 day",
  },
  {
    icon: FiActivity,
    name: "Type",
    title: "Adventure",
  },
  {
    icon: FiUsers,
    name: "Group Size:",
    title: "50 Peoples",
  },
  {
    icon: FiGlobe,
    name: "Languages",
    title: "English",
  },
  {
    icon: FiDollarSign,
    name: "$50 / Person",
    title: "1 Day",
  },
];

// export const blogData = [
//     {
//         id:1,
//         image:blog1,
//         date:'13th Sep 2024',
//         title:'This Spanish city is a feast for the eyes: Travosy',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Travel'
//     },
//     {
//         id:2,
//         image:blog2,
//         date:'29th Nov 2024',
//         title:'New Zealand’s South Island brims with majestic',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Tour'
//     },
//     {
//         id:3,
//         image:blog3,
//         date:'29th Dec 2024',
//         title:'When you visit the Eternal Rome City: Travosy',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Tourist'
//     },
//     {
//         id:4,
//         image:blog4,
//         date:'13th March 2024',
//         title:'My Story When I Backpacked Around The World',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Flight'
//     },
//     {
//         id:5,
//         image:blog5,
//         date:'5th May 2024',
//         title:'Organization of accounting at the enterprise',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Arab'
//     },
//     {
//         id:6,
//         image:blog6,
//         date:'19th June 2024',
//         title:'Three of the Best Day Trips to Make from Francisco',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Dubai'
//     },
//     {
//         id:7,
//         image:blog7,
//         date:'20th June 2024',
//         title:'Why Do People Travel ? Reasons People Travel in 2023',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Maldivas'
//     },
//     {
//         id:8,
//         image:blog8,
//         date:'31st Aug 2024',
//         title:'Jungles In Australia: Vermont’s Rugged, Retro Ski Mountain',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'News'
//     },
//     {
//         id:9,
//         image:blog9,
//         date:'1st Sep 2024',
//         title:'Traveller Visiting Ice Cave With Amazing Eye-catching Scenes',
//         desc:'This is required when, for example, the final text is not yet available.',
//         tag:'Packages'
//     },
// ]

// export const faqData = [
//     {
//         id:1,
//         title:'How does it work ?',
//         desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
//     },
//     {
//         id:2,
//         title:'Do I need a designer to use Travosy ?',
//         desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
//     },
//     {
//         id:3,
//         title:'What do I need to do to start selling ?',
//         desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
//     },
//     {
//         id:4,
//         title:'What happens when I receive an order ?',
//         desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
//     },
//     {
//         id:5,
//         title:'How does it work ?',
//         desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
//     },
// ]

// export const paymentData = [
//     {
//         image:payment1,
//         title:'Visa ending in 4578',
//         date:'Expires in 13/03/2024'
//     },
//     {
//         image:payment2,
//         title:'American Express ending in 4578',
//         date:'Expires in 05/05/2024'
//     },
//     {
//         image:payment3,
//         title:'Discover ending in 4578',
//         date:'Expires in 19/06/2024'
//     },
//     {
//         image:payment4,
//         title:'Master Card ending in 4578',
//         date:'Expires in 20/06/2024'
//     },
// ]

// export const userSocialData = [
//     {
//         icon:FiTwitter,
//         name:'Twitter',
//         placeHolder:'Twitter Profile Name',
//         desc:'Add your Twitter username (e.g. jesus).'
//     },
//     {
//         icon:FiFacebook,
//         name:'Facebook',
//         placeHolder:'Facebook Profile Name',
//         desc:'Add your Facebook username (e.g. jesus).'
//     },
//     {
//         icon:FiInstagram,
//         name:'Instagram',
//         placeHolder:'Instagram Profile Name',
//         desc:'Add your Instagram username (e.g. jesus).'
//     },
//     {
//         icon:FiLinkedin,
//         name:'Linkedin',
//         placeHolder:'Linkedin Profile Name',
//         desc:'Add your Linkedin username (e.g. jesus).'
//     },
//     {
//         icon:FiYoutube,
//         name:'Youtube',
//         placeHolder:'Youtube url',
//         desc:'Add your Youtube url.'
//     },
// ]

// export const helpAbout = [
//     {
//         icon:FiHelpCircle,
//         title:'FAQs',
//         desc:'The phrasal sequence of the is now so that many campaign and benefit',
//         link:'/helpcenter-faqs'
//     },
//     {
//         icon:FiBookmark,
//         title:'Guides / Support',
//         desc:'The phrasal sequence of the is now so that many campaign and benefit',
//         link:'/helpcenter-guides'
//     },
//     {
//         icon:FiSettings,
//         title:'Support Request',
//         desc:'The phrasal sequence of the is now so that many campaign and benefit',
//         link:'/helpcenter-support'
//     },
// ]

// export const guidesData = [
//     {
//         title:'Getting started',
//         subData:[
//             'Deciding to purchase','List your space','Landing an experience or adventure','Top uses questions'
//         ]
//     },
//     {
//         title:'Your calendar',
//         subData:[
//             'Pricing & availability','Booking settings','Responding to enquiries & requests','Snoozing or deactivating your listing'
//         ]
//     },
//     {
//         title:'Your listings',
//         subData:[
//             'Updating your listing','Neighbourhoods','Listing photos & photography','Travosy Plus','API-connected software'
//         ]
//     },
//     {
//         title:'How payouts work',
//         subData:[
//             'Getting paid','Adding payout info','Your payout status','Donations','Taxes'
//         ]
//     },
//     {
//         title:'Your reservations',
//         subData:[
//             'Travosy safely','Travosy Experiences and Adventures','Changing a reservation','Cancelling a reservation','Long-term reservations'
//         ]
//     },
//     {
//         title:'Reservation help',
//         subData:[
//             'Help with a reservation or guest','Guest cancellations',
//         ]
//     },
//     {
//         title:'Your account',
//         subData:[
//             'Your profile','Account security','Identification & verifications','Reviews','Superhost status'
//         ]
//     },
// ]

// export const restrictions = [
//     'Digital Marketing Solutions for Tomorrow','Our Talented & Experienced Marketing Agency','Create your own skin to match your brand','Digital Marketing Solutions for Tomorrow','Our Talented & Experienced Marketing Agency','Create your own skin to match your brand'
// ]

// export const blogSocial = [
//     FiFacebook,FiInstagram,FiTwitter,FiLinkedin,FiGithub,FiYoutube,FiGitlab
// ]
