import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";
// import Form from "./form";

interface HeroProps {}

const Hero = ({}: HeroProps) => {
  const { i18n, t } = useTranslation();
  return (
    <div>
      <Swiper
        className="swiper-wrapper"
        loop={true}
        speed={2000}
        autoplay={{ delay: 4000 }}
        effect="fade"
        modules={[Autoplay, EffectFade]}
      >
        <SwiperSlide>
          <section
            className="relative md:pt-48 md:pb-36 py-36 table w-full items-center bg-center bg-hero4"
            id="home"
          >
            <div className="absolute inset-0"></div>
            <div className="relative">
              <div className="grid grid-cols-1 text-center mb-12">
                <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-6xl mb-6 mt-5">
                  {t("Let the journey begin...")}
                </h1>
                <p className="text-white/70 text-xl max-w-xl mx-auto">
                  {t(
                    "Planning for a trip? We will organize your trip with the best places and within best budget!"
                  )}
                </p>
              </div>
              {/* <Form /> */}
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section
            className="relative md:pt-48 md:pb-36 py-36 table w-full items-center bg-center bg-hero5"
            id="home"
          >
            <div className="absolute inset-0 bg-slate-900/40"></div>
            <div className="relative">
              <div className="grid grid-cols-1 text-center mb-12">
                <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-6xl mb-6 mt-5">
                  {t("Let the journey begin...")}
                </h1>
                <p className="text-white/70 text-xl max-w-xl mx-auto">
                  {t(
                    "Planning for a trip? We will organize your trip with the best places and within best budget!"
                  )}
                </p>
              </div>
              {/* <Form /> */}
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section
            className="relative md:pt-48 md:pb-36 py-36 table w-full items-center bg-center bg-hero6"
            id="home"
          >
            <div className="absolute inset-0 bg-slate-900/40"></div>
            <div className="relative">
              <div className="grid grid-cols-1 text-center mb-12">
                <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-6xl mb-6 mt-5">
                  {t("Let the journey begin...")}
                </h1>
                <p className="text-white/70 text-xl max-w-xl mx-auto">
                  {t(
                    "Planning for a trip? We will organize your trip with the best places and within best budget!"
                  )}
                </p>
              </div>
              {/* <Form /> */}
            </div>
          </section>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;
