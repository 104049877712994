// PACKAGES list
export const GET_PACKAGES_LOADING = "GET_PACKAGES_LOADING";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAILURE = "GET_PACKAGES_FAILURE";

//  tours
export const GET_TOURS_LOADING = "GET_TOURS_LOADING";
export const GET_TOURS_SUCCESS = "GET_TOURS_SUCCESS";
export const GET_TOURS_FAILURE = "GET_TOURS_FAILURE";

// details
export const GET_DETAILS_LOADING = "GET_DETAILS_LOADING";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_FAILURE = "GET_DETAILS_FAILURE";
